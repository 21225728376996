<template>
  <div class="contact-form">
    <b-container class="container">
      <b-row align-h="between" align-v="center">
        <b-col cols="12" md="3">
          <b-row align-h="center">
            <div class="logo-form-canvas">
              <img
                class="logo-form"
                :src="require('@/assets/svg/logo-smaterials.svg')"
                alt=""
              />
            </div>
          </b-row>
        </b-col>

        <b-col cols="12" md="5">
          <h2 class="ap-form-block second-title">
            Расскажите о себе, чтобы специалист по подбору персонала смог
            связаться с вами, если есть подходящая вакансия.
          </h2>
        </b-col>

        <b-col cols="12" md="4">
          <b-row align-h="center">
            <button v-b-modal.ap_form__modal id="button_outline">
              Отправить
            </button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="ap_form__modal"
      hide-footer
      hide-header
      body-class="applicant_body"
      content-class="applicant_content"
      size="lg"
      centered
      #default="{ hide }"
    >
      <b-container>
        <b-form @submit="sendForm">
          <b-row align-h="center" align-v="center">
            <b-col cols="12" md="8" class="applicant_form">
              <!-- Header -->
              <div class="ap_form__header">
                <h2 class="applicant_form__title">
                  Заполните и отправьте форму cоискателя
                </h2>

                <p class="applicant_form__text">
                  Мы молодая и амбициозная компания оказывающая услуги на рынке
                  тендерных закупок по всей России.
                </p>

                <h3 class="applicant_form__subtitle">
                  {{ apFormModal[apFormIndex].subtitle }}
                </h3>
              </div>

              <!-- Step one -->
              <div v-if="apFormIndex == 0" class="callme__inputs">
                <b-form-select
                  class="applicant_input"
                  v-model="form.vacancy"
                  :options="vacancies.items"
                  :value-field="'alternate_url'"
                  :text-field="'name'"
                  required
                ></b-form-select>
              </div>

              <!-- Step two -->
              <b-row
                align-h="center"
                v-else-if="apFormIndex == 1"
                class="callme__inputs"
              >
                <b-col cols="12" md="8">
                  <b-form-input
                    block
                    type="text"
                    id="name"
                    placeholder="Имя"
                    v-model="form.name"
                    required
                    class="applicant_input"
                  ></b-form-input>

                  <b-form-input
                    block
                    type="tel"
                    id="phone"
                    required
                    v-model="form.phone"
                    placeholder="Телефон"
                    class="applicant_input"
                  ></b-form-input>
                </b-col>
              </b-row>

              <!-- Step three -->
              <div v-else-if="apFormIndex == 2" class="callme__inputs">
                <b-form-input
                  block
                  type="url"
                  id="link"
                  required
                  v-model="form.portfolio"
                  placeholder="Ссылка на портфолио"
                  class="applicant_input"
                ></b-form-input>
              </div>

              <!-- Footer -->
              <b-row align-h="center">
                <b-col cols="12" md="6">
                  <b-button
                    type="button"
                    v-if="apFormIndex == 0"
                    @click="hide()"
                    class="ap_form__button_close"
                    id="button_outline"
                  >
                    {{ apFormModal[apFormIndex].prevButton }}
                  </b-button>

                  <b-button
                    v-else
                    @click="prevStep()"
                    type="button"
                    class="ap_form__button_close"
                    id="button_outline"
                  >
                    {{ apFormModal[apFormIndex].prevButton }}
                  </b-button>
                </b-col>

                <b-col cols="12" md="6">
                  <b-button
                    v-if="
                      apFormIndex == apFormModal.length - 1 &&
                      form.portfolio != null
                    "
                    @click="nextStep()"
                    type="submit"
                    class="ap_form__button_send"
                    id="button_outline"
                    :disabled="disabled"
                  >
                    {{ buttonText }}
                  </b-button>

                  <b-button
                    v-else
                    @click="nextStep()"
                    type="button"
                    class="ap_form__button_send"
                    id="button_outline"
                  >
                    {{ apFormModal[apFormIndex].nextButton }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ApForm",
  props: {
    vacancies: Object,
  },
  data() {
    return {
      // data modal
      apFormIndex: 0,
      apFormModal: [
        {
          subtitle: "ШАГ 1 – Выберите вакансию",
          nextButton: "Далее",
          prevButton: "Отмена",
        },
        {
          subtitle: "ШАГ 2 – Укажите личные данные",
          nextButton: "Далее",
          prevButton: "Назад",
        },
        {
          subtitle: "ШАГ 3 – Портфолио",
          nextButton: "Отправить",
          prevButton: "Назад",
        },
      ],

      form: {
        portfolio: null,
        vacancy: null,
        name: null,
        phone: null,
        title: 'Форма "Соискателю"',
      },

      disabled: false,
      buttonText: "Отправить",
    };
  },
  methods: {
    nextStep() {
      if (this.apFormModal && this.apFormIndex < this.apFormModal.length - 1) {
        this.apFormIndex++;
      }
    },
    prevStep() {
      if (this.apFormModal && this.apFormIndex > 0) {
        this.apFormIndex--;
      }
    },
    async sendForm(ev) {
      ev.preventDefault();

      this.disabled = true;

      try {
        await this.$store.dispatch("sendApplicantForm", this.form);

        this.buttonText = "Отправлено";
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
/* ap-form-block */
.contact-form {
  background-color: $gray;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  position: relative;
  z-index: 2;
}
.ap-form-block.second-title {
  color: $white;
  font-size: 18px;
  padding-bottom: 15px;
}
.bronze {
  color: $accent;
}
.logo-form-canvas {
  width: 140px;
  height: 140px;
  position: relative;
}
.logo-form {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}
.logo-form_logo-wrapper {
  display: flex;
  justify-content: flex-start;
}

//ap_form

.applicant_form__title {
  color: $white;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
}
.applicant_form__text {
  color: $gray__ultra_light;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 60px;
}
.applicant_form__subtitle {
  color: $white;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}

#button_outline.ap_form__button_close {
  width: 100%;
  border-color: none;
  margin-bottom: 15px;
  border: none;
  background-color: rgba($color: $gray-light, $alpha: 1);
  transition: all 300ms;
}
#button_outline.ap_form__button_close:hover {
  background-color: rgba($color: $gray-light, $alpha: 0.8);
  transition: all 300ms;
}
#button_outline.ap_form__button_send {
  width: 100%;
  background-color: $white;
  color: $black;
}
#button_outline.ap_form__button_send:hover {
  background-color: $accent;
}

// .applicant_body {
//   background-color: rgba($color: $gray, $alpha: 0.8);
//   border-radius: 30px;
// }
.applicant_content {
  background-color: rgba($color: $gray, $alpha: 0.9);
  border-radius: 15px;
  border: 1px solid $accent;
}
.applicant_form {
  padding-top: 60px;
  padding-bottom: 60px;
}
.applicant_input {
  background-color: unset;
  color: $gray__ultra_light;
  font-weight: 300;
  border: 1px solid $gray-light-darken;
  margin-bottom: 15px;
}
.applicant_input:focus,
.applicant_input:focus-visible {
  background-color: unset;
  color: $white;
  border: 1px solid $white;
  outline: none;
  box-shadow: none;
}
</style>
