<template>
  <div>
    <div class="recm-proc-header">
      <img
        class="recm_proc_header__img"
        :src="require('@/assets/photo/_S__3156.jpg')"
        alt=""
      />
      <img class="rictangle__recm" src="@/assets/img/rictangle.png" alt="" />
      <div class="bg_aplicant__filter"></div>

      <b-container>
        <b-row
          align-h="center"
          align-v="center"
          class="recm-proc-header__content"
        >
          <b-col cols="12" md="8">
            <div class="recm-proc-header__text_wrapper">
              <h1 class="header_title">
                Выберите вакансию и станьте частью нашей команды
              </h1>

              <p class="text_one">
                Приглашаем на работу компетентных и талантливых специалистов,
                готовых раскрыть свой потенциал в дружном и сильном коллективе.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <Processes />

    <Vacancies :vacancies="vacancies" />

    <ApForm :vacancies="vacancies" />

    <!-- <News /> -->
  </div>
</template>

<script>
import ApForm from "@/components/ApForm.vue";
import News from "@/components/News.vue";
import Vacancies from "@/components/Vacancies.vue";
import Processes from "@/components/Processes.vue";
import axios from "axios";

export default {
  components: {
    ApForm,
    News,
    Vacancies,
    Processes,
  },
  data() {
    return {
      vacancies: null,
    };
  },
  async mounted() {
    try {
      let vacancies = await axios.get(
        "https://api.hh.ru/vacancies?employer_id=5192954"
      );

      this.vacancies = vacancies.data;
    } catch (e) {
      this.$bvToast.toast(`${e}`);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.recm-proc-header {
  position: relative;
  overflow: hidden;
  height: 100vh;
  margin-bottom: 100px;
}
.bg_aplicant__filter {
  z-index: 4;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba( $black, 0.7);
}
.recm_proc_header__img {
  z-index: 3;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.rictangle__recm {
  top: 80%;
  left: 100%;

  z-index: 1;
  position: absolute;
  height: 140vh;

  transform: translate(-50%, -50%);
  overflow: hidden;
}
.recm-proc-header__content {
  min-height: 100vh;
  padding-top: 30vh;
  padding-bottom: 30vh;

  position: relative;
  z-index: 4;
}

.recm-proc-header__title {
  color: $white;
  font-size: 30px;
  font-weight: 500;
  text-align: center;

  position: relative;
  z-index: 4;
}
.recm-proc-header__text {
  color: $white;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  position: relative;
  z-index: 4;
}
</style>
