<template>
  <div>
    <div class="line-title-left">
      <b-container>
        <b-row align-v="center" align-h="between">
          <b-col>
            <h2 class="header_title-page">Открытые вакансии</h2>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="vacancy-view" class="vacancy" v-if="vacancies">
      <b-container>
        <b-row align-h="center">
          <b-col>
            <div class="vacancy_control">
              <button
                class="vacancy_link"
                v-for="(item, index) in vacancies.items"
                :key="index"
                :class="[
                  activeVacancy && item.id == activeVacancy.id ? 'active' : '',
                  'vacancy_link',
                ]"
                @click="viewVacancy(item.id)"
              >
                {{ item.name }}
              </button>
            </div>
          </b-col>
        </b-row>

        <b-row align-v="start" align-h="center" v-if="activeVacancy">
          <b-col cols="12">
            <h2 class="vacancy_title">{{ activeVacancy.name }}</h2>
          </b-col>

          <b-col cols="12">
            <p class="vacancy_salary">
              {{ printSalary(activeVacancy.salary) }}
            </p>
          </b-col>

          <b-col cols="12" v-if="activeVacancy.description">
            <p class="vacancy_text" v-html="activeVacancy.description"></p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Vacancies",
  data() {
    return {
      activeVacancy: null,
    };
  },
  props: {
    vacancies: Object,
  },
  methods: {
    printSalary(salary) {
      let printText;
      let from = Intl.NumberFormat("ru-RU").format(salary.from);
      let to = Intl.NumberFormat("ru-RU").format(salary.to);

      if (salary.from && !salary.to) {
        printText = `от ${from}`;
      } else if (salary.from && salary.to) {
        printText = `${from} - ${to}`;
      } else if (!salary.from && salary.to) {
        printText = `до ${to}`;
      }

      if (salary.currency == "RUR") {
        printText += ` руб.`;
      } else {
        printText += ` ${salary.currency}`;
      }

      return printText;
    },
    async viewVacancy(vacancyId) {
      if (vacancyId) {
        this.$router.push({
          path: `/vacancies?vacancy=${vacancyId}#vacancy-view`,
        });
      } else {
        vacancyId = this.$route.query.vacancy;
      }

      if (!vacancyId) return;

      try {
        let vacancy = await axios.get(
          `https://api.hh.ru/vacancies/${vacancyId}`
        );

        this.activeVacancy = vacancy.data;
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
  },
  async mounted() {
    await this.viewVacancy();
  },
};
</script>

<style lang="scss">
// vacancy
.vacancy {
  padding-bottom: 100px;
}
.vacancy_control {
  padding-bottom: 60px;
}
.vacancy_link {
  color: $gray__ultra_light;
  font-size: 16px;
  font-weight: 400;

  line-height: 24px;

  margin: 5px;

  border: 1px solid rgba($color: $accent, $alpha: 0.8);
  border-radius: 5px;
  padding: 3px 15px;
}
.vacancy_link.active {
  color: $accent;
}

.vacancy_wrapper {
  width: 100%;
  height: 480px;

  position: relative;
  overflow: hidden;

  border-radius: 5px;
}
.vacancy_img {
  position: absolute;

  height: 100%;
  width: 100%;

  object-fit: cover;
}

.vacancy_title {
  color: $accent;
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 30px;
}
.vacancy_subtitle {
  color: $white;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}
.vacancy_text {
  color: $gray__ultra_light;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 30px;
}
.vacancy_salary {
  font-size: 22px;
  font-weight: 700;
}
</style>
