<template>
  <div>
    <b-container class="process">
      <b-row>
        <b-col cols="12" md="4">
          <div class="process_img-canvas">
            <div class="img-filter"></div>

            <p class="process_num">0{{ active + 1 }}</p>
            <img
              v-if="process[active].img"
              class="process_img"
              :src="
                require('@/assets/img/aplicant/process/' + process[active].img)
              "
              alt=""
            />
          </div>
        </b-col>

        <b-col cols="12" md="8">
          <b-row>
            <b-col>
              <h3 class="process_title">Как мы работаем с соискателями</h3>
              <!-- <p class="process_subtitle">
                Только профессиональные решения для клиентов
              </p> -->
            </b-col>
          </b-row>

          <b-row class="process_links">
            <b-col
              cols="12"
              md="4"
              v-for="(item, index) in process"
              :key="index"
            >
              <button
                v-bind:class="[index == active ? 'active' : '', 'process_link']"
                @click="updateProcess(index)"
              >
                <span class="num_link_menu">{{ index + 1 }}.</span>
                {{ item.title }}
              </button>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p v-if="process[active].text" class="process_text">
                {{ process[active].text }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Processes",
  data() {
    return {
      active: 0,
      process: [
        {
          title: "Отправка резюме",
          img: "one.png",
          text: `
          Заполните анкету на нашем сайте и прикрепите резюме. Эти документы получит для анализа специалист по найму персонала. 
          `,
        },
        {
          title: "Анализ резюме",
          img: "img4.jpg",
          text: `
          Специалист по найму персонала изучает резюме и анкеты на предмет соответствия открытым вакансиям. Период рассмотрения резюме может занимать от 1 до 4 недель. Если кандидат соответствует требованиям, переходим к следующему этапу. 
          `,
        },
        {
          title: "Тестирование",
          img: "three.png",
          text: `
          Соискатель проходит тестирование в удаленном формате. Результаты теста обрабатывает специалист по найму персонала. Период обработки результатов занимает от 1 до 2 недель. Если тест пройден удачно, кандидата по телефону пригласят на живое собеседование.
          `,
        },
        {
          title: "Живое собеседование",
          img: "four.png",
          text: `
          Мы приглашаем соискателя в офис на собеседование, которое проводят руководители компании. По итогам будет принято окончательное решение.
          `,
        },
        {
          title: "Вступление в должность ",
          img: "img5.jpg",
          text: `
          Каждому новому сотруднику мы даём период для адаптации в новом пространстве для спокойного вступления в должность и знакомства с коллективом. Такой подход позволяет максимально быстро проявить компетенции и стать эффективным специалистом. 
          `,
        },
      ],
      updateProcess(index) {
        this.active = index;
      },
    };
  },
};
</script>

<style lang="scss">
.process {
  padding-bottom: 100px;
}
.process_img-canvas {
  min-height: 60vh;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.process_img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}
.img-filter {
  background-color: rgba($black, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 1;
}
.process_num {
  position: absolute;
  bottom: 15px;
  right: 15px;

  font-size: 100px;
  line-height: 100px;
  font-weight: 700;
  margin: 0;
  color: $accent;

  z-index: 2;
}
.process_title {
  color: $white;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 15px;
}
.process_subtitle {
  color: rgba($white, 0.5);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 30px;
}
.process_links {
  padding-bottom: 15px;
  position: relative;
  z-index: 4;
}
.process_link {
  color: rgba($white, 0.5);
  font-size: 16px;
  text-align: start;
  padding-bottom: 15px;
}
.process_link.active {
  color: $accent;
}
.process_text {
  position: relative;
  z-index: 4;
  color: $white;
  font-size: 16px;
  font-weight: 400;
}
.num_link_menu {
  font-size: 18px;
  font-weight: 500;
}
</style>
